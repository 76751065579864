import { Coordinates } from '~/generated/graphql';

// Converts numeric degrees to radians
function toRad(value: number) {
  return (value * Math.PI) / 180;
}

/**
 * Return the distance between two points using Haversine formula
 */
export default function getDistance({ point1, point2 }: { point1?: Coordinates | null; point2?: Coordinates | null }): number | null {
  if (!point1?.lat || !point1?.lng || !point2?.lat || !point2?.lng) {
    return null;
  }

  const R = 3959; // Earth radius in ml

  const dLat = toRad(point2.lat - point1.lat);
  const dLon = toRad(point2.lng - point1.lng);
  const lat1 = toRad(point1.lat);
  const lat2 = toRad(point2.lat);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return Number(d.toFixed(1));
}
