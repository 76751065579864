const isPullRequest = process.env.IS_PULL_REQUEST === 'true' || process.env.NEXT_PUBLIC_IS_PULL_REQUEST === 'true';
const renderExternalUrl = process.env.RENDER_EXTERNAL_URL! || process.env.NEXT_PUBLIC_RENDER_EXTERNAL_URL!;

const baseApiUrl = isPullRequest
  ? renderExternalUrl.replace('https://wl-frontend-staging', 'https://wl-graphql-staging')
  : process.env.NEXT_PUBLIC_REACT_APP_API_BASE_URL!;
// const baseApiUrl = 'https://api.welcomelend.com';

const baseWebsiteUrl = isPullRequest ? renderExternalUrl : process.env.NEXT_PUBLIC_REACT_APP_WEBSITE_BASE_URL;

export const getBaseApiUrl = () => baseApiUrl;

export const getBaseWebsiteUrl = () => baseWebsiteUrl;
