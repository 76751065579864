import { DealType } from '~/generated/graphql';

const bridge = '/img/line-icons/svg/bridge.svg';
const buildToRent = '/img/line-icons/svg/single-family-home.svg';
const cannabis = '/img/line-icons/svg/cannabis.svg';
const condominiums = '/img/line-icons/svg/mixed-use.svg';
const equity = '/img/line-icons/svg/equity.svg';
const fixAndFlip = '/img/line-icons/svg/fix-and-flip.svg';
const groundUp = '/img/line-icons/svg/ground-up.svg';
const hotel = '/img/line-icons/svg/hotel.svg';
const industrial = '/img/line-icons/svg/industrial.svg';
const land = '/img/line-icons/svg/land.svg';
const landAssetTypeIcon = '/img/line-icons/svg/land.svg';
const lifeScience = '/img/line-icons/svg/life-science.svg';
const medical = '/img/line-icons/svg/life-science.svg';
const mezzanine = '/img/line-icons/svg/equity.svg';
const miniPermanent = '/img/line-icons/svg/permanent.svg';
const mixedUse = '/img/line-icons/svg/mixed-use.svg';
const mobileHomePark = '/img/line-icons/svg/mobile-home.svg';
const multifamily = '/img/line-icons/svg/multi-family.svg';
const office = '/img/line-icons/svg/office.svg';
const other = '/img/line-icons/svg/other.svg';
const pace = '/img/line-icons/svg/pace.svg';
const permanent = '/img/line-icons/svg/permanent.svg';
const retail = '/img/line-icons/svg/retail.svg';
const selfStorage = '/img/line-icons/svg/self-storage.svg';
const seniorLiving = '/img/line-icons/svg/senior-living.svg';
const singleFamily = '/img/line-icons/svg/single-family-home.svg';
const studentHousing = '/img/line-icons/svg/student-housing.svg';
const townhouses = '/img/line-icons/svg/townhouses.svg';
const valueAdd = '/img/line-icons/svg/value-add.svg';

export const assetTypeImages: Record<string, string> = {
  cannabis,
  hotel,
  industrial,
  land: landAssetTypeIcon,
  medical,
  mixedUse,
  mobileHomePark,
  multifamily,
  office,
  lifeScience,
  condominiums,
  other,
  retail,
  selfStorage,
  seniorLiving,
  singleFamily,
  studentHousing,
  townhouses,
  buildToRent,
};

export const loanTypeImages: Record<string, string> = {
  [DealType.BridgeAcquisition]: bridge,
  [DealType.PermanentAcquisition]: permanent,
  [DealType.BridgeRefinance]: bridge,
  [DealType.PermanentRefinance]: permanent,
  [DealType.LandDevelopment]: groundUp,
  [DealType.GroundUpDevelopment]: groundUp,
  [DealType.ValueAddDevelopment]: groundUp,
  groundUp,
  land,
  bridge,
  valueAdd,
  permanent,
  miniPermanent,
  equity,
  mezzanine,
  pace,
  fixAndFlip,
};
