/**
 * Return the first and the last chr for the full name
 */
const formatName = (fullName: string, opts = { initialLastName: false }) => {
  if (opts?.initialLastName) {
    const names = fullName.split(' ');

    if (names.length === 1) {
      return fullName;
    }

    const firstName = names?.[0];
    const lastName = names?.[names.length - 1];

    return `${firstName} ${lastName && lastName[0]}.`;
  }
  const initials = [];
  const arr = fullName && fullName.trim() ? fullName.trim().split(' ') : ' ';

  if (arr.length) {
    initials.push(arr[0].charAt(0));

    if (arr.length > 1) {
      initials.push(arr[arr.length - 1].charAt(0));
    }
  }

  return initials.join('');
};

export default formatName;
