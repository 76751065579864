import { createGlobalStyle, css } from 'styled-components';

const Global = createGlobalStyle`${({ theme }) => css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    line-height: ${theme.lineHeight.base};
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    font-size: ${theme.fontSize.base}px;
    font-family: ${theme.fontFamily.base};
    font-weight: ${theme.fontWeight.base};
    line-height: ${theme.lineHeight.base};
    background-color: ${theme.bodyBg};
    color: ${theme.bodyColor};
  }

  #root {
    width: 100%;
  }

  main {
    display: block;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  ul, ol {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p {
    margin-top: 0;
    margin-bottom 1rem;
  }

  pre {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  a:hover {
    cursor: pointer;
  }

  a, .button {
    background-color: transparent;
    color: ${theme.linkColor};
    text-decoration: none;

    &:hover {
      color: ${theme.linkHoverColor};
      text-decoration: none;
    }
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  b,
  strong {
    font-weight: ${theme.fontWeight.bold};
  }

  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }

  small {
    font-size: 87.5%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  img {
    border-style: none;
    vertical-align: middle;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: ${theme.fontWeight.base};
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: none; // button
  }

  button:focus-visible {
    outline: none;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted;
  }

  [data-placeholder]:empty:before {
    content: attr(data-placeholder);
    color: ${theme.inputPlaceholderColor};
    cursor: text;
  }


  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  details {
    display: block;
  }

  summary {
    display: list-item;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none;
  }

  [role="button"] {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .ReactCollapse--collapse {
    transition: ${theme.transition.lg};
    transition-property: height;
  }

  blockquote {
    margin: 0 0 1rem 0;
  }

  blockquote::before {
    content: open-quote;
  }

  blockquote::after {
    content: close-quote;
  }

  svg:focus, svg:active {
    outline: none;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    height: ${theme.borderWidth * 3}px;
    background: ${theme.colors.primary};
  }

  #nprogress .peg,
  #nprogress .spinner {
    display: none;
  }

  // .nprogress-custom-parent {
  //   overflow: hidden;
  //   position: relative;
  // }

  // .nprogress-custom-parent #nprogress .bar {
  //   position: absolute;
  // }

  a[href*='maps.google.com/maps'] {
    display: none !important;
  }

  .gmnoprint a,
  .gmnoprint span,
  .gm-style-cc {
    display: none;
  }

  @media(max-width: ${theme.breakpoints.desktop - 1}px) {
    .gmnoprint {
      display: none;
    }
  }

  .gm-style [style*="https://maps.gstatic.com/mapfiles/openhand_8_8.cur"] {
    cursor: grab !important;
  }

  .gm-style [style*="https://maps.gstatic.com/mapfiles/closedhand_8_8.cur"] {
    cursor: grabbing !important;
  }

  .bp-lock {
    overflow: visible;
  }

  .bp-lock > body {
    overflow: visible;
  }

  .bp-wrap {
    z-index: ${theme.zindex.modal};
  }

  .bp-wrap > div:first-child {
    background-color: ${theme.modalBg} !important;
  }

  .bp-x {
    display: none;
  }

  .bp-img {
    cursor: default;
  }

  [style*="font-weight: normal"] {
    font-weight: ${theme.fontWeight.base} !important;
  }

  [style*="font-weight: bold"] {
    font-weight: ${theme.fontWeight.bold} !important;
  }

  [style*="font-weight: bolder"] {
    font-weight: ${theme.fontWeight.bolder} !important;
  }

  [data-tippy-root][style*="z-index: 9999;"] {
    z-index: ${theme.zindex.fixed} !important;
  }

  iframe {
    border: 0;
  }
`}`;

export default Global;
