function intlFormat(num: number) {
  return new Intl.NumberFormat('en-US').format(Math.round(num * 10) / 10);
}

function formatNumber(value: number): string | number {
  if (value >= 1000000000) {
    return intlFormat(value / 1000000000) + 'B';
  }

  if (value >= 1000000) {
    return intlFormat(value / 1000000) + 'M';
  }

  if (value >= 1000) {
    return intlFormat(value / 1000) + 'K';
  }
  
  return value;
}

export default formatNumber;
