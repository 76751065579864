import { getBaseApiUrl } from './getBaseUrl';

// We don't import this value from Theme because it's breaking hot reload
export const avatarVariantMapped: Record<string, string> = {
  'antique-brass': '#C99067',
  'blue-marguerite': '#8067C9',
  'fuchsia-pink': '#C967BF',
  'steel-blue': '#6892CF',
  contessa: '#C96767',
  danube: '#68B0CF',
  downy: '#67C9C9',
  emerald: '#67C97D',
  laser: '#C9C567',
};

export const avatarVariants = Object.keys(avatarVariantMapped);

function findSum(str: string) {
  let temp = '0';
  let sum = 0;

  for (let i = 0; i < str.length; i++) {
    const ch = str[i];

    if (!isNaN(parseInt(ch))) {
      temp += ch;
    } else {
      sum += parseInt(temp);
      temp = '0';
    }
  }
  return sum + parseInt(temp);
}

export function getAvatarVariant(id: string) {
  const parsedIdInt = findSum(id);
  let avatarVariant = avatarVariants[0];

  for (let index = avatarVariants.length - 1; index > 0; index--) {
    if (parsedIdInt % index === 0) {
      avatarVariant = avatarVariants[index];
      break;
    }
  }
  return avatarVariant;
}

type User = {
  _id?: string;
  profileImageSet?: boolean | null;
  profileFileId?: string | null;
  id?: string;
  avatar_id?: string;
  lenderContact?: {
    avatar_id?: string | null;
  } | null;
  borrowerContact?: {
    avatarId?: string | null;
  } | null;
};

type GetAvatarUrlProps = {
  user: User;
  width?: number;
  height?: number;
};

export const getAvatarUrl = ({ user, width = 96, height = 96 }: GetAvatarUrlProps) => {
  if (user?.profileImageSet && user?.profileFileId?.includes('blob')) {
    return user.profileFileId;
  }
  if (user?.profileImageSet || user?.borrowerContact?.avatarId) {
    return `${getBaseApiUrl()}/api/images/${user.profileFileId || user?.borrowerContact?.avatarId}?width=${width}&height=${height}`;
  }
  // For lender contacts
  if (user?.avatar_id || user?.lenderContact?.avatar_id) {
    return `${getBaseApiUrl()}/api/lender/file/${user?.avatar_id || user?.lenderContact?.avatar_id}?width=${width}&height=${height}`;
  }
  if (user?._id || user?.id) {
    return getAvatarVariant((user?._id || user?.id)!);
  }
  return '';
};
