export default function formatNumbers(value: number) {
  if (value < 1000000) {
    return Math.floor(value / 1000) + 'K';
  }

  if (value < 100000000) {
    return Math.floor((value * 10) / 1000000) / 10 + 'M';
  }

  if (value < 1000000000) {
    return Math.floor(value / 1000000) + 'M';
  }

  return Math.floor((value * 10) / 1000000000) / 10 + 'B';
}
