import { NormalizedFolderTree, NormalizedFolderTreeItem } from './getNormalizedFolderTree';

export type FolderOption = {
  value: string;
  label: string;
  request?: {
    title: string;
    depth?: number;
  };
  folder?: {
    title: string;
    depth?: number;
    adminsOnlyAccess?: boolean | null;
  };
};

/**
 * Return folder options for the select component
 *
 * @param  data  All requests + folder as object (tranformed data structure from tree)
 * @param  ignoreRoot If you need to remove root option (add request select component)
 * @param  hiddenFolderId If you need to remove folder and all it's subfolders(edit folder modal)
 */
export const getFolderOptions = ({
  data,
  ignoreRoot,
  hiddenFolderId,
  skipRequests = true,
}: {
  data: NormalizedFolderTree;
  ignoreRoot?: boolean;
  hiddenFolderId?: string;
  skipRequests?: boolean;
}): FolderOption[] => {
  const options: NormalizedFolderTreeItem[] = [];

  const addChildrenOptions = (value: NormalizedFolderTreeItem, depth = -1, isIgnored = false) => {
    // Return if it's not a folder
    if (value.isRequest && skipRequests) {
      return;
    }

    // Remove ignored id
    // Good example: folder edit modal(we must hide current folder)
    const isFolderHidden = hiddenFolderId && hiddenFolderId === value.id;
    if (!isIgnored && isFolderHidden) {
      isIgnored = true;
    }

    // Add depth property
    value.depth = depth;

    // Add option
    if (!isIgnored) {
      options.push(value);
    }

    // Loop through children
    if (value.children && value.children.length) {
      value.children.forEach((child) => {
        addChildrenOptions(data.items[child], depth + 1, isIgnored);
      });
    }
  };

  // Add folder options
  addChildrenOptions(data.items['root']);

  // Transform for React Select
  const result = options.map((option) => {
    const metadata = option?.isRequest
      ? {
          request: {
            title: option.title === 'root' ? 'At The Top Level' : option.title,
            depth: option.depth,
          },
        }
      : {
          folder: {
            title: option.title === 'root' ? 'At The Top Level' : option.title,
            depth: option.depth,
            adminsOnlyAccess: option.adminsOnlyAccess,
          },
        };
    return {
      value: option.id,
      label: option.title === 'root' ? 'At The Top Level' : option.title,
      ...metadata,
    };
  });

  // Remove root if required
  if (ignoreRoot) {
    result.shift();
  }

  return result;
};

export default getFolderOptions;
