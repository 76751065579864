import { darken, fadeIn, formatSVG } from '~/helpers';

// Color system

const black = '#031831';
const white = '#FFFFFF';
const primary = '#4F5EE8';
const warning = '#E5BA20';
const danger = '#E63757';
const success = '#2ACB87';
const blue = '#31B8F1';
const purple = '#C7CDF7';
const transparent = 'transparent';

const blacks = {
  black: black,
  black025: fadeIn(black, 0.025),
  black10: fadeIn(black, 0.1),
  black20: fadeIn(black, 0.2),
  black30: fadeIn(black, 0.3),
  black40: fadeIn(black, 0.4),
  black50: fadeIn(black, 0.5),
  black60: fadeIn(black, 0.6),
  black70: fadeIn(black, 0.7),
  black80: fadeIn(black, 0.8),
  black90: fadeIn(black, 0.9),
};

const whites = {
  white: white,
  white05: fadeIn(white, 0.05),
  white10: fadeIn(white, 0.1),
  white20: fadeIn(white, 0.2),
  white30: fadeIn(white, 0.3),
  white40: fadeIn(white, 0.4),
  white50: fadeIn(white, 0.5),
  white60: fadeIn(white, 0.6),
  white70: fadeIn(white, 0.7),
  white80: fadeIn(white, 0.8),
  white90: fadeIn(white, 0.9),
};

const grays = {
  gray900: '#283E59',
  gray800: '#3F5570',
  gray700: '#6E84A3',
  gray600: '#95AAC9',
  gray500: '#B1C2D9',
  gray400: '#CAD5E4',
  gray300: '#E4EBF6',
  gray200: '#EDF2F9',
  gray100: '#FBFCFD',
};

const primaries = {
  primary: primary,
  primary10: fadeIn(primary, 0.1),
  primary15: fadeIn(primary, 0.15),
  primary20: fadeIn(primary, 0.2),
  primary30: fadeIn(primary, 0.3),
  primary40: fadeIn(primary, 0.4),
  primary50: fadeIn(primary, 0.5),
  primary60: fadeIn(primary, 0.6),
  primary70: fadeIn(primary, 0.7),
  primary80: fadeIn(primary, 0.8),
  primary90: fadeIn(primary, 0.9),
};

const warnings = {
  warning: warning,
  warning10: fadeIn(warning, 0.1),
  warning20: fadeIn(warning, 0.2),
  warning30: fadeIn(warning, 0.3),
  warning40: fadeIn(warning, 0.4),
  warning50: fadeIn(warning, 0.5),
  warning60: fadeIn(warning, 0.6),
  warning70: fadeIn(warning, 0.7),
  warning80: fadeIn(warning, 0.8),
  warning90: fadeIn(warning, 0.9),
};

const dangers = {
  danger: danger,
  danger10: fadeIn(danger, 0.1),
  danger20: fadeIn(danger, 0.2),
  danger30: fadeIn(danger, 0.3),
  danger40: fadeIn(danger, 0.4),
  danger50: fadeIn(danger, 0.5),
  danger60: fadeIn(danger, 0.6),
  danger70: fadeIn(danger, 0.7),
  danger80: fadeIn(danger, 0.8),
  danger90: fadeIn(danger, 0.9),
};

const successes = {
  success: success,
  success10: fadeIn(success, 0.1),
  success20: fadeIn(success, 0.2),
  success30: fadeIn(success, 0.3),
  success40: fadeIn(success, 0.4),
  success50: fadeIn(success, 0.5),
  success60: fadeIn(success, 0.6),
  success70: fadeIn(success, 0.7),
  success80: fadeIn(success, 0.8),
  success90: fadeIn(success, 0.9),
};

const blues = {
  blue: blue,
  blue10: fadeIn(blue, 0.1),
  blue20: fadeIn(blue, 0.2),
  blue30: fadeIn(blue, 0.3),
  blue40: fadeIn(blue, 0.4),
  blue50: fadeIn(blue, 0.5),
  blue60: fadeIn(blue, 0.6),
  blue70: fadeIn(blue, 0.7),
  blue80: fadeIn(blue, 0.8),
  blue90: fadeIn(blue, 0.9),
};

const purples = {
  purple: purple,
  purple10: fadeIn(purple, 0.1),
  purple20: fadeIn(purple, 0.2),
  purple30: fadeIn(purple, 0.3),
  purple40: fadeIn(purple, 0.4),
  purple50: fadeIn(purple, 0.5),
  purple60: fadeIn(purple, 0.6),
  purple70: fadeIn(purple, 0.7),
  purple80: fadeIn(purple, 0.8),
  purple90: fadeIn(purple, 0.9),
};

const colors = {
  ...blacks,
  ...whites,
  ...grays,
  ...primaries,
  ...warnings,
  ...dangers,
  ...successes,
  ...blues,
  ...purples,
  ...{ transparent },
};

// Spacers

const spacers = {
  '-24': -416,
  '-23': -384,
  '-22': -352,
  '-20': -320,
  '-21': -288,
  '-19': -256,
  '-18': -224,
  '-17': -192,
  '-16': -160,
  '-15': -128,
  '-14': -96,
  '-13': -80,
  '-12': -64,
  '-11': -48,
  '-10': -40,
  '-9': -32,
  '-8': -24,
  '-7': -20,
  '-6': -16,
  '-5': -12,
  '-4': -8,
  '-3': -6,
  '-2': -4,
  '-1': -2,
  0: 0,
  1: 2,
  2: 4,
  3: 6,
  4: 8,
  5: 12,
  6: 16,
  7: 20,
  8: 24,
  9: 32,
  10: 40,
  11: 48,
  12: 64,
  13: 80,
  14: 96,
  15: 128,
  16: 160,
  17: 192,
  18: 224,
  19: 256,
  20: 288,
  21: 320,
  22: 352,
  23: 384,
  24: 416,
};

// Body

const bodyBg = colors.white;
const bodyColor = colors.black;

// Links

const linkColor = colors.primary;
const linkHoverColor = darken(linkColor, 0.05);

// Grid

const gridGutterX = spacers[7];

// Grid container

const containerMaxWidth = 1240;
const containerGutterX = gridGutterX;

// Borders

const borderWidth = 1;
const borderColor = colors.gray300;

const borderRadius = {
  '2xs': 4,
  xs: 6,
  sm: 8,
  base: 10,
  lg: 12,
  xl: 16,
  '2xl': 20,
  none: 0,
  rounded: 100,
};

// Box shadows

const boxShadow = {
  none: 'none',
  1: '0 1px 0 ' + colors.gray200,
  2: '0 1px 2px rgba(0, 0, 0, .03)',
  3: '0 6px 24px rgba(31, 45, 61, 0.03)',
  4: '0 6px 24px rgba(31, 45, 61, 0.06)',
  5: '0 0 60px ' + colors.black10,
  6: '0 0 100px ' + colors.black10,
};

// Transitions

const transition = {
  none: 'none',
  lg: 'all .3s ease-out',
  base: 'all .15s ease-out',
  modal: 'all .5s cubic-bezier(.21,.52,.19,.99)',
  financing: 'all .3s ease-out',
};

// Typography

const fontFamily = {
  base: 'TTNormsPro',
};

const fontSize = {
  mobile: {
    '3xs': 8,
    '2xs': 10,
    xs: 13,
    sm: 14,
    base: 16,
    lg: 18,
    xl: 18,
    '2xl': 20,
    '3xl': 20,
    '4xl': 24,
    '5xl': 26,
    '6xl': 28,
    '7xl': 32,
    '8xl': 32,
  },
  '3xs': 8,
  '2xs': 10,
  xs: 13,
  sm: 14,
  base: 16,
  lg: 18,
  xl: 20,
  '2xl': 22,
  '3xl': 24,
  '4xl': 26,
  '5xl': 32,
  '6xl': 40,
  '7xl': 48,
  '8xl': 56,
};

const fontWeight = {
  base: 440,
  bold: 560,
  bolder: 680,
};

const lineHeight = {
  sm: 1.3,
  base: 1.5,
};

const headingFontWeight = {
  '3xs': fontWeight.bold,
  '2xs': fontWeight.bold,
  xs: fontWeight.bold,
  sm: fontWeight.bold,
  base: fontWeight.bold,
  lg: fontWeight.bold,
  xl: fontWeight.bolder,
  '2xl': fontWeight.bolder,
  '3xl': fontWeight.bolder,
  '4xl': fontWeight.bolder,
  '5xl': fontWeight.bolder,
  '6xl': fontWeight.bolder,
  '7xl': fontWeight.bolder,
  '8xl': fontWeight.bolder,
};

const headingLineHeight = {
  '3xs': null,
  '2xs': null,
  xs: null,
  sm: null,
  base: null,
  lg: null,
  xl: null,
  '2xl': null,
  '3xl': null,
  '4xl': null,
  '5xl': null,
  '6xl': 1.2,
  '7xl': 1.07,
  '8xl': 1.07,
};

const headingLetterSpacing = {
  '3xs': '-.01em',
  '2xs': '-.01em',
  xs: '-.01em',
  sm: '-.01em',
  base: '-.02em',
  lg: '-.02em',
  xl: '-.02em',
  '2xl': '-.02em',
  '3xl': '-.02em',
  '4xl': '-.02em',
  '5xl': '-.02em',
  '6xl': '-.02em',
  '7xl': '-.02em',
  '8xl': '-.04em',
};

// Horizontal rule

const hrSpacingY = spacers[7];
const hrBorderWidth = borderWidth;
const hrBorderColor = colors.gray200;
const hrSmallWidth = 100;

// Vertical rule

const vrHeight = `calc(1em * ${lineHeight.base})`;
const vrSpacingX = spacers[5];
const vrBorderWidth = borderWidth;
const vrBorderColor = borderColor;

// Buttons + Forms

const inputButtonPaddingY = {
  xs: spacers[1],
  sm: spacers[2],
  base: 10, // YEVGENY: Replace with a spacers value when those are extended
};

const inputButtonPaddingX = {
  xs: 10, // YEVGENY: Replace with a spacers value when those are extended
  sm: spacers[5],
  base: spacers[6],
};

const inputButtonBorderWidth = borderWidth;
const inputButtonLineHeight = fontSize.base * lineHeight.base - inputButtonBorderWidth * 2;
const inputButtonBoxShadow = boxShadow[2];

// Dropdowns

const dropdownMenuWidth = '10rem';
const dropdownMenuOffset = spacers[4];
const dropdownMenuPaddingY = spacers[2];
const dropdownMenuBorderWidth = borderWidth;
const dropdownMenuBorderColor = borderColor;
const dropdownMenuBorderRadius = borderRadius.base;
const dropdownMenuBg = colors.white;
const dropdownMenuBoxShadow = boxShadow[4];

const dropdownItemPaddingY = spacers[4];
const dropdownItemPaddingX = spacers[5];
const dropdownItemMarginX = dropdownMenuPaddingY;
const dropdownItemBorderRadius = borderRadius.sm;
const dropdownItemFontSize = fontSize.xs;
const dropdownItemBg = colors.white;
const dropdownItemColor = colors.black;
const dropdownItemHoverBg = colors.gray200;
const dropdownItemHoverColor = colors.black;
const dropdownItemActiveBg = dropdownItemHoverBg;
const dropdownItemActiveColor = dropdownItemHoverColor;

const dropdownDividerHeight = dropdownMenuBorderWidth;
const dropdownDividerMarginY = dropdownMenuPaddingY;
const dropdownDividerMarginX = 0;
const dropdownDividerBg = dropdownMenuBorderColor;
const dropdownMenuArrowSize = 6;

// Forms

const inputPaddingY = inputButtonPaddingY;
const inputPaddingX = inputButtonPaddingX;
const inputLineHeight = inputButtonLineHeight;
const inputBg = colors.white;
const inputColor = colors.black;
const inputBorderWidth = inputButtonBorderWidth;
const inputBorderColor = colors.gray300;
const inputHoverBorderColor = inputBorderColor;
const inputBoxShadow = inputButtonBoxShadow;
const inputPlaceholderColor = colors.gray600;

const inputBorderRadius = {
  xs: borderRadius.xs,
  sm: borderRadius.base,
  base: borderRadius.lg,
};

const inputFontSize = {
  xs: fontSize.xs,
  sm: fontSize.sm,
  base: fontSize.base,
};

const inputHeight = {
  xs: inputPaddingY.xs * 2 + inputBorderWidth * 2 + inputLineHeight,
  sm: inputPaddingY.sm * 2 + inputBorderWidth * 2 + inputLineHeight,
  base: inputPaddingY.base * 2 + inputBorderWidth * 2 + inputLineHeight,
};

const inputFocusBg = inputBg;
const inputFocusBorderColor = colors.primary;

const labelFontSize = fontSize.base;

const checkboxFontSize = fontSize.sm;
const checkboxColor = colors.gray700;
const checkboxSize = spacers[6];
const checkboxGutter = spacers[5];

const checkboxIndicatorBg = colors.gray400;
const checkboxIndicatorActiveBg = colors.primary;
const checkboxIndicatorIcon =
  "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1.60156 3L4.10156 5.5L8.60156 1'/%3e%3c/svg%3e\")";
const checkboxIndicatorCloseIcon =
  "url(\"data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='0' y1='0' x2='8' y2='8' /><line x1='8' y1='0' x2='0' y2='8' /></svg>\")";
const checkboxDisableIcon =
  "url(\"data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%23DAE2EC'/%3E%3Cline x1='4' y1='8' x2='12' y2='8' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A\")";

const checkboxSquareInputSize = fontSize.base;
const checkboxSquareInputBorderRadius = borderRadius.xs / 2;
const checkboxSquareInputBorderColor = colors.gray500;
const checkboxSquareInputActiveBorderColor = colors.primary;
const checkboxSquareInputBg = colors.white;
const checkboxSquareInputActiveBg = colors.primary;
const checkboxSquareInputActiveColor = colors.white;
const checkboxSquareInputActiveIcon = `<svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11.9141 5.20575C11.8599 5.15108 11.7954 5.10768 11.7243 5.07807C11.6532 5.04845 11.5769 5.0332 11.4999 5.0332C11.4229 5.0332 11.3467 5.04845 11.2756 5.07807C11.2045 5.10768 11.14 5.15108 11.0858 5.20575L6.73993 9.55742L4.9141 7.72575C4.85779 7.67136 4.79133 7.6286 4.71849 7.59989C4.64566 7.57119 4.56789 7.55712 4.48962 7.55847C4.41135 7.55982 4.33411 7.57658 4.26231 7.60779C4.19052 7.63899 4.12557 7.68403 4.07118 7.74034C4.01679 7.79664 3.97402 7.86311 3.94532 7.93594C3.91662 8.00877 3.90254 8.08654 3.9039 8.16481C3.90525 8.24309 3.92201 8.32033 3.95321 8.39212C3.98442 8.46392 4.02946 8.52886 4.08576 8.58325L6.32576 10.8233C6.37999 10.8779 6.44451 10.9213 6.51559 10.9509C6.58668 10.9806 6.66292 10.9958 6.73993 10.9958C6.81694 10.9958 6.89318 10.9806 6.96427 10.9509C7.03535 10.9213 7.09987 10.8779 7.1541 10.8233L11.9141 6.06325C11.9733 6.00863 12.0206 5.94233 12.0529 5.86854C12.0852 5.79475 12.1019 5.71506 12.1019 5.6345C12.1019 5.55394 12.0852 5.47426 12.0529 5.40047C12.0206 5.32667 11.9733 5.26038 11.9141 5.20575Z' fill='${checkboxSquareInputActiveColor}'/></svg>`;
const checkboxSquareInputDisabledBorderColor = colors.gray300;
const checkboxSquareInputDisabledBg = colors.gray200;

const selectInputMaxHeight = 250;
const selectInputPaddingY = inputPaddingY;
const selectInputPaddingX = inputPaddingX;
const selectInputBorderWidth = inputBorderWidth;
const selectInputBorderColor = inputBorderColor;
const selectInputBorderRadius = inputBorderRadius;
const selectInputBg = inputBg;
const selectInputBoxShadow = inputBoxShadow;
const selectInputFontSize = inputFontSize;
const selectInputLineHeight = inputLineHeight;
const selectInputHoverBorderColor = inputHoverBorderColor;
const selectInputFocusBorderColor = colors.primary;
const selectInputPlaceholderColor = inputPlaceholderColor;

const selectMultiValueMarginX = spacers[1];
const selectMultiPaddingX = spacers[5];
const selectMultiBg = colors.primary10;
const selectMultiBorderRadius = borderRadius.rounded;
const selectMultiColor = colors.primary;
const selectMultiValueFontSize = 12;
const selectMultiValueLineHeight = 20;
const selectMultiValueCloseSize = fontSize.base;
const selectMultiValueCloseColor = colors.primary;
const selectMultiValueCloseHoverBg = colors.primary20;
const selectMultiValueCloseHoverBgInvalid = colors.danger20;
const selectMultiValueCloseIcon = `<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><path d='M8.75 1.25L1.25 8.75' stroke='${selectMultiValueCloseColor}' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/><path d='M1.25 1.25L8.75 8.75' stroke='${selectMultiValueCloseColor}' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/></svg>`;
const selectMultiValueCloseIconInvalid = `<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><path d='M8.75 1.25L1.25 8.75' stroke='${colors.danger}' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/><path d='M1.25 1.25L8.75 8.75' stroke='${colors.danger}' stroke-width='1.08333' stroke-linecap='round' stroke-linejoin='round'/></svg>`;
const selectMultiValueCloseIconSize = 8;

const selectClearIcon = `<svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 4L4 12' stroke='${colors.gray600}' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/><path d='M4 4L12 12' stroke='${colors.gray600}' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/></svg>`;
const selectClearIconHover = `<svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M12 4L4 12' stroke='${colors.gray800}' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/><path d='M4 4L12 12' stroke='${colors.gray800}' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/></svg>`;
const selectClearIconSize = selectInputFontSize;

const selectMenuPaddingY = dropdownMenuPaddingY;
const selectMenuBorderWidth = dropdownMenuBorderWidth;
const selectMenuBorderColor = dropdownMenuBorderColor;
const selectMenuBorderRadius = dropdownMenuBorderRadius;
const selectMenuBg = dropdownMenuBg;
const selectMenuBoxShadow = 'none';
const selectMenuItemMarginX = dropdownItemMarginX;
const selectMenuItemPaddingY = dropdownItemPaddingY;
const selectMenuItemPaddingX = dropdownItemPaddingX;
const selectMenuItemFontSize = fontSize.sm;
const selectMenuItemBorderRadius = dropdownItemBorderRadius;
const selectMenuItemBg = dropdownItemBg;
const selectMenuItemHoverBg = dropdownItemHoverBg;
const selectMenuItemActiveBg = dropdownItemActiveBg;
const selectMenuItemColor = dropdownItemColor;
const selectMenuItemHoverColor = dropdownItemHoverColor;
const selectMenuItemActiveColor = dropdownItemActiveColor;

const selectIcon = `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='${colors.gray600}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><polyline points='6 9 12 15 18 9'></polyline></svg>`;
const selectIconSize = {
  sm: fontSize['2xs'],
  base: fontSize.base,
};

// Buttons

const buttonPaddingY = inputButtonPaddingY;
const buttonPaddingX = inputButtonPaddingX;
const buttonLineHeight = inputButtonLineHeight;
const buttonBorderWidth = inputButtonBorderWidth;
const buttonBoxShadow = inputButtonBoxShadow;

const buttonFontSize = {
  xs: fontSize.xs,
  sm: fontSize.xs,
  base: fontSize.base,
};

const buttonBorderRadius = {
  xs: borderRadius.sm,
  sm: borderRadius.base,
  base: borderRadius.lg,
};

const buttonIconSize = {
  xs: spacers[5],
  sm: spacers[6],
  base: spacers[7],
};

const buttonWhiteBg = colors.white;
const buttonWhiteHoverBg = buttonWhiteBg;
const buttonWhiteBorderColor = inputBorderColor;
const buttonWhiteHoverBorderColor = colors.primary;
const buttonWhiteColor = bodyColor;
const buttonWhiteHoverColor = colors.primary;

// Z-index master list

const zindex = {
  sticky: 1010,
  header: 1020,
  fixed: 1030,
  modal: 1040,
  dropdown: 1050,
  select: 1060,
  alert: 1070,
  confetti: 1080,
  tooltip: 1090,
};

// Navs

const navPaddingY = {
  sm: spacers[6],
  base: spacers[8],
};

const navPaddingX = {
  sm: spacers[4],
  base: spacers[6],
};

const navLinkFontSize = fontSize.sm;
const navLinkColor = colors.gray700;
const navLinkHoverColor = colors.primary;
const navLinkFocusColor = colors.primary;

// Header

const headerHeight = {
  base: 68,
  sm: 53,
  lg: 85,
};

const headerBg = colors.white;
const headerBorderWidth = borderWidth;
const headerBorderColor = hrBorderColor;

const headerDarkBg = colors.primary;
const headerDarkBorderColor = colors.primary;

// Topnav

const topnavSize = { sm: 50, base: 60 };
const topnavPaddingX = { sm: spacers[6], base: spacers[8] };

const topnavBg = colors.white;
const topnavBorderWidth = borderWidth;
const topnavBorderColor = hrBorderColor;

const topnavLinkSpacer = spacers[2];
const topnavLinkFontSize = fontSize.sm;
const topnavLinkColor = colors.gray700;
const topnavLinkHoverBg = colors.gray200;
const topnavLinkHoverColor = colors.black;
const topnavLinkActiveBg = colors.primary10;
const topnavLinkActiveColor = colors.primary;

// Sidenav

const sidenavWidth = 72;
const sidenavBg = colors.black;
const sidenavBorderRadius = borderRadius.lg;

// Grid breakpoints

const breakpoints = {
  desktop: 992 + sidenavWidth,
};

// Toggler

const togglerWidth = 20;
const togglerHeight = 16;
const togglerColor = colors.gray600;

// Cards

const cardBorderWidth = borderWidth;
const cardBorderColor = borderColor;
const cardBorderRadius = borderRadius.xl;
const cardBg = colors.white;
const cardBoxShadow = boxShadow[3];
const cardIconSize = 70;

const cardSpacing = {
  xs: spacers[6],
  sm: spacers[8],
  base: spacers[10],
};

// Tooltips

const tooltipMaxWidth = 250;
const tooltipPaddingY = spacers[4];
const tooltipPaddingX = spacers[5];
const tooltipFontSize = fontSize.xs;
const tooltipBg = colors.black;
const tooltipBorderRadius = borderRadius.base;
const tooltipBoxShadow = boxShadow[3];
const tooltipColor = colors.white;

const tooltipArrowSize = 14;
const tooltipArrowRadius = 2;

// Badges

const badgePaddingY = {
  base: 1, // YEVGENY: Replace with a spacers value when those are extended
  lg: spacers[2],
};

const badgePaddingX = {
  base: spacers[4],
  lg: spacers[5],
};

const badgeLineHeight = 16;

const badgeFontSize = {
  base: fontSize['2xs'],
  lg: fontSize.xs,
};

const badgeCloseSize = {
  base: 16,
  lg: 20,
};

const badgeCountSize = {
  base: 16,
  lg: 18,
};

const badgeHeight = {
  base: 18, // YEVGENY: Replace with a spacers value when those are extended
  lg: 26,
};

// Modals

const modalBg = colors.black90;
const modalDialogMarginY = spacers[6];

const modalSize = {
  base: 408,
  lg: 750,
};

const modalSizeHorizontal = 670;

// Alerts

const alertPaddingY = 10; // YEVGENY: Replace with a spacer value then those are extended
const alertPaddingX = spacers[7];
const alertFontSize = fontSize.sm;
const alertBorderRadius = borderRadius.base;

// Progress

const progressHeight = 5;
const progressWidth = 100;
const progressBg = colors.gray400;

// List group

const listItemSpacing = spacers[5];

const listItemIcon =
  "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 7'%3e%3cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1.60156 3L4.10156 5.5L8.60156 1'/%3e%3c/svg%3e\")";
const listItemIconSize = spacers[6];
const listItemIconGutter = spacers[5];

const listGroupItemHeight = {
  xs: 48,
  sm: 56,
  base: 72,
};

const listGroupItemPaddingY = {
  xs: spacers[3],
  sm: spacers[6],
  base: spacers[7],
};

const listFolderHeight = {
  base: 23,
  xs: 12,
  sm: 19,
  lg: 27,
};

const listFolderWidth = {
  base: 25,
  xs: 13,
  sm: 21,
  lg: 30,
};

// Avatar

const avatarImageBgColor = {
  light: colors.black,
  dark: colors.white,
};

const avatarVariant = {
  base: colors.gray500,
  'antique-brass': '#C99067',
  'blue-marguerite': '#8067C9',
  'fuchsia-pink': '#C967BF',
  'steel-blue': '#6892CF',
  contessa: '#C96767',
  danube: '#68B0CF',
  downy: '#67C9C9',
  emerald: '#67C97D',
  laser: '#C9C567',
};

const avatarFontSize = {
  '3xs': fontSize['3xs'],
  '2xs': fontSize['2xs'],
  xs: 11, // YEVGENY: Shouldn't be hardcoded
  sm: fontSize.sm,
  base: fontSize.base,
  lg: fontSize.lg,
  xl: fontSize.xl,
  '2xl': fontSize['2xl'],
};

const avatarSize = {
  '3xs': 16,
  '2xs': 20,
  xs: 26,
  sm: 32,
  base: 40,
  lg: 48,
  xl: 60,
  '2xl': 80,
};

const avatarStatus = {
  away: colors.warning,
  offline: colors.gray600,
  online: colors.success,
};

// Spinner

const spinnerSize = {
  base: 32,
  xs: 16,
  sm: 24,
};

// File

const fileIconWidth = 31;
const fileIconHeight = 40;

const fileModalBodyWidth = 600;
const fileModalBodyBg = colors.white;

// Preview

const previewModalRightWidth = 600;
const previewModalRightBg = colors.white;
const previewModalLeftGutter = spacers[6];

// Dropzone

const dropzoneContainerSpacing = spacers[8];
const dropzoneContainerBorderRadius = borderRadius.base;
const dropzoneContainerBorderWidth = borderWidth;
const dropzoneContainerBorderColor = colors.gray400;
const dropzoneContainerActiveBorderColor = colors.primary;
const dropzoneContainerActiveBg = fadeIn(colors.primary, 0.05);

// Status

const statusColors = {
  approved: colors.success,
  rejected: colors.danger,
  review: colors.primary,
};

const statusSize = 14;
const statusIconSize = `${(10 / 14) * 100}%`;

// Chart

const chartHeight = 200;

// Switch

const switchWidth = { sm: 29, base: 40 };
const switchHeight = { sm: 16, base: 22 };
const switchBackgroundImage = formatSVG(
  `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'><circle cx='11' cy='11' r='8' fill='${colors.white}' /></svg>`,
);
const switchDisabledOpacity = 0.5;

// Table

const tableBgColor = colors.white;
const tableHoverBgColor = colors.gray100;

const tableBorderWidth = hrBorderWidth;
const tableBorderColor = hrBorderColor;

const tableHeaderBgColor = tableBgColor;

const tableFontSize = {
  sm: fontSize.sm,
  base: fontSize.sm,
  lg: fontSize.sm,
};

const tableCellHeight = {
  sm: 48,
  base: 72,
  lg: 90,
};

const tableHeaderHeight = {
  sm: 48,
  base: 48,
  lg: 48,
};

const tableIconFontSize = fontSize.lg;
const tableCollapseOffset = spacers[2];

const tableScrollStartedBorderWidth = 0;
const tableScrollStartedBorderColor = colors.transparent;
const tableScrollStartedGradientSize = spacers[8];
const tableScrollStartedGradientFrom = colors.black025;
const tableScrollStartedGradientTo = colors.transparent;

const tableResponsiveMinWidth = 750;

// Images

const imageFallbackColor = colors.gray300;

// Not found

const notFoundSpacing = {
  xs: spacers[6],
  sm: spacers[8],
  base: spacers[10],
  lg: spacers[10],
};

const notFoundHeadingMarginBottom = {
  xs: spacers[1],
  sm: spacers[1],
  base: spacers[0],
  lg: spacers[0],
};

const notFoundHeadingFontSize = {
  xs: fontSize['sm'],
  sm: fontSize['base'],
  base: fontSize['xl'],
  lg: fontSize['2xl'],
};

const notFoundHeadingFontWeight = {
  xs: fontWeight['base'],
  sm: headingFontWeight['base'],
  base: headingFontWeight['xl'],
  lg: headingFontWeight['2xl'],
};

const notFoundContentFontSize = {
  xs: fontSize['sm'],
  sm: fontSize['sm'],
  base: fontSize['base'],
  lg: fontSize['base'],
};

// Editor

const editorHeight = '3em';
const editorHeightSm = '1em';

const editorToolbarPaddingY = spacers[2];
const editorToolbarPaddingX = spacers[5];

const editorToolbarButtonSize = spacers[9];
const editorToolbarButtonFontSize = fontSize.lg;
const editorToolbarButtonBg = colors.white;
const editorToolbarButtonBorderRadius = borderRadius.base;
const editorToolbarButtonColor = colors.gray700;
const editorToolbarButtonHoverColor = colors.black;
const editorToolbarButtonActiveColor = colors.primary;

const editorToolbarLinkInputWidth = 250;

// Market

const marketIconBg = colors.white;
const marketIconBorderRadius = borderRadius.sm;
const marketIconBorderWidth = borderWidth;
const marketIconBorderColor = colors.white;
const marketIconFontSize = fontSize.sm;
const marketIconFontWeight = fontWeight.bold;
const marketIconColor = bodyColor;
const marketIconBoxShadow = `0 2px 6px ${colors.black20}`;
const marketIconArrowSize = 8;

const marketIconBgInverse = colors.black;
const marketIconBorderColorInverse = colors.black;
const marketIconColorInverse = colors.white;

const marketSubnavSpacer = spacers[4];
const marketSubnavPadding = spacers[2];
const marketSubnavBorderRadius = borderRadius.rounded;
const marketSubnavBoxShadow = `0 2px 6px ${colors.black20}`;

const markertCircleBg = colors.primary10;
const markertCircleBorderWidth = borderWidth * 2;
const markertCircleBorderColor = colors.primary;

const marketCircleLabelPaddingY = spacers[1];
const marketCircleLabelPaddingX = spacers[2];
const marketCircleLabelBg = colors.primary;
const marketCircleLabelBorderRadius = borderRadius.rounded;
const markertCircleLabelFontSize = fontSize['2xs'];
const markertCircleLabelFontWeight = fontWeight.bold;
const markertCircleLabelColor = colors.white;

// Radio group

const radioGroupBg = colors.gray200;
const radioGroupBorderWidth = borderWidth;
const radioGrouipBorderColor = borderColor;

const radioButtonActiveBg = colors.white;
const radioButtonActiveBorderColor = borderColor;
const radioButtonActiveColor = colors.black;
const radioButtonActiveBoxShadow = buttonBoxShadow;

// PDF

const pdf = {
  pageWidth: 595,
  containerMaxWidth: 595 - spacers[10] * 2,
  borderWidth: borderWidth * 0.625,
  borderColor: colors.gray200,
  fontSize: {
    '3xs': 5,
    '2xs': 6,
    xs: 8,
    sm: 9,
    base: 10,
    lg: 11,
    xl: 12,
    '2xl': 13,
    '3xl': 15,
    '4xl': 16,
    '5xl': 20,
    '6xl': 25,
    '7xl': 30,
    '8xl': 35,
  },
  buttonPaddingY: {
    xs: buttonPaddingY.sm * 0.625,
    sm: buttonPaddingY.sm * 0.625,
    base: buttonPaddingY.base * 0.625,
  },
  buttonPaddingX: {
    xs: buttonPaddingX.sm * 0.625,
    sm: buttonPaddingX.sm * 0.625,
    base: buttonPaddingX.base * 0.625,
  },
  buttonFontSize: {
    xs: 9,
    sm: 9,
    base: 10,
  },
  buttonBorderRadius: {
    xs: buttonBorderRadius.xs * 0.625,
    sm: buttonBorderRadius.sm * 0.625,
    base: buttonBorderRadius.base * 0.625,
  },
  buttonBorderWidth: buttonBorderWidth * 0.625,
};

// Carousel

const carouselScrollEndedGradientSize = spacers[12];
const carouselScrollEndedGradientFrom = colors.white;
const carouselScrollEndedGradientTo = fadeIn(colors.white, 0);

// Rating

const ratingItemColor = colors.gray400;
const ratingItemActiveColor = colors.warning;

// Theme

const theme = {
  alertBorderRadius,
  alertFontSize,
  alertPaddingX,
  alertPaddingY,
  avatarFontSize,
  avatarImageBgColor,
  avatarSize,
  avatarStatus,
  avatarVariant,
  badgeCloseSize,
  badgeCountSize,
  badgeFontSize,
  badgeHeight,
  badgeLineHeight,
  badgePaddingX,
  badgePaddingY,
  bodyBg,
  bodyColor,
  borderColor,
  borderRadius,
  borderWidth,
  boxShadow,
  breakpoints,
  buttonBorderRadius,
  buttonBorderWidth,
  buttonBoxShadow,
  buttonFontSize,
  buttonIconSize,
  buttonLineHeight,
  buttonPaddingX,
  buttonPaddingY,
  buttonWhiteBg,
  buttonWhiteBorderColor,
  buttonWhiteColor,
  buttonWhiteHoverBg,
  buttonWhiteHoverBorderColor,
  buttonWhiteHoverColor,
  cardBg,
  cardBorderColor,
  cardBorderRadius,
  cardBorderWidth,
  cardBoxShadow,
  cardIconSize,
  cardSpacing,
  carouselScrollEndedGradientFrom,
  carouselScrollEndedGradientSize,
  carouselScrollEndedGradientTo,
  chartHeight,
  checkboxColor,
  checkboxDisableIcon,
  checkboxFontSize,
  checkboxGutter,
  checkboxIndicatorActiveBg,
  checkboxIndicatorBg,
  checkboxIndicatorCloseIcon,
  checkboxIndicatorIcon,
  checkboxSize,
  checkboxSquareInputActiveBg,
  checkboxSquareInputActiveBorderColor,
  checkboxSquareInputActiveColor,
  checkboxSquareInputActiveIcon,
  checkboxSquareInputBg,
  checkboxSquareInputBorderColor,
  checkboxSquareInputBorderRadius,
  checkboxSquareInputDisabledBg,
  checkboxSquareInputDisabledBorderColor,
  checkboxSquareInputSize,
  colors,
  containerGutterX,
  containerMaxWidth,
  dropdownDividerBg,
  dropdownDividerHeight,
  dropdownDividerMarginX,
  dropdownDividerMarginY,
  dropdownItemActiveBg,
  dropdownItemActiveColor,
  dropdownItemBg,
  dropdownItemBorderRadius,
  dropdownItemColor,
  dropdownItemFontSize,
  dropdownItemHoverBg,
  dropdownItemHoverColor,
  dropdownItemMarginX,
  dropdownItemPaddingX,
  dropdownItemPaddingY,
  dropdownMenuArrowSize,
  dropdownMenuBg,
  dropdownMenuBorderColor,
  dropdownMenuBorderRadius,
  dropdownMenuBorderWidth,
  dropdownMenuBoxShadow,
  dropdownMenuOffset,
  dropdownMenuPaddingY,
  dropdownMenuWidth,
  dropzoneContainerActiveBg,
  dropzoneContainerActiveBorderColor,
  dropzoneContainerBorderColor,
  dropzoneContainerBorderRadius,
  dropzoneContainerBorderWidth,
  dropzoneContainerSpacing,
  editorHeight,
  editorHeightSm,
  editorToolbarButtonActiveColor,
  editorToolbarButtonBg,
  editorToolbarButtonBorderRadius,
  editorToolbarButtonColor,
  editorToolbarButtonFontSize,
  editorToolbarButtonHoverColor,
  editorToolbarButtonSize,
  editorToolbarLinkInputWidth,
  editorToolbarPaddingX,
  editorToolbarPaddingY,
  fileIconHeight,
  fileIconWidth,
  fileModalBodyBg,
  fileModalBodyWidth,
  fontFamily,
  fontSize,
  fontWeight,
  gridGutterX,
  headerBg,
  headerBorderColor,
  headerBorderWidth,
  headerDarkBg,
  headerDarkBorderColor,
  headerHeight,
  headingFontWeight,
  headingLetterSpacing,
  headingLineHeight,
  hrBorderColor,
  hrBorderWidth,
  hrSmallWidth,
  hrSpacingY,
  imageFallbackColor,
  inputBg,
  inputBorderColor,
  inputBorderRadius,
  inputBorderWidth,
  inputBoxShadow,
  inputButtonPaddingX,
  inputButtonPaddingY,
  inputColor,
  inputFocusBg,
  inputFocusBorderColor,
  inputFontSize,
  inputHeight,
  inputHoverBorderColor,
  inputLineHeight,
  inputPaddingX,
  inputPaddingY,
  inputPlaceholderColor,
  labelFontSize,
  lineHeight,
  linkColor,
  linkHoverColor,
  listFolderHeight,
  listFolderWidth,
  listGroupItemHeight,
  listGroupItemPaddingY,
  listItemIcon,
  listItemIconGutter,
  listItemIconSize,
  listItemSpacing,
  markertCircleBg,
  markertCircleBorderColor,
  markertCircleBorderWidth,
  markertCircleLabelColor,
  markertCircleLabelFontSize,
  markertCircleLabelFontWeight,
  marketCircleLabelBg,
  marketCircleLabelBorderRadius,
  marketCircleLabelPaddingX,
  marketCircleLabelPaddingY,
  marketIconArrowSize,
  marketIconBg,
  marketIconBgInverse,
  marketIconBorderColor,
  marketIconBorderColorInverse,
  marketIconBorderRadius,
  marketIconBorderWidth,
  marketIconBoxShadow,
  marketIconColor,
  marketIconColorInverse,
  marketIconFontSize,
  marketIconFontWeight,
  marketSubnavBorderRadius,
  marketSubnavBoxShadow,
  marketSubnavPadding,
  marketSubnavSpacer,
  modalBg,
  modalDialogMarginY,
  modalSize,
  modalSizeHorizontal,
  navLinkColor,
  navLinkFocusColor,
  navLinkFontSize,
  navLinkHoverColor,
  navPaddingX,
  navPaddingY,
  notFoundContentFontSize,
  notFoundHeadingFontSize,
  notFoundHeadingFontWeight,
  notFoundHeadingMarginBottom,
  notFoundSpacing,
  pdf,
  previewModalLeftGutter,
  previewModalRightBg,
  previewModalRightWidth,
  progressBg,
  progressHeight,
  progressWidth,
  radioButtonActiveBg,
  radioButtonActiveBorderColor,
  radioButtonActiveBoxShadow,
  radioButtonActiveColor,
  radioGrouipBorderColor,
  radioGroupBg,
  radioGroupBorderWidth,
  ratingItemActiveColor,
  ratingItemColor,
  selectClearIcon,
  selectClearIconHover,
  selectClearIconSize,
  selectIcon,
  selectIconSize,
  selectInputBg,
  selectInputBorderColor,
  selectInputBorderRadius,
  selectInputBorderWidth,
  selectInputBoxShadow,
  selectInputFocusBorderColor,
  selectInputFontSize,
  selectInputHoverBorderColor,
  selectInputLineHeight,
  selectInputMaxHeight,
  selectInputPaddingX,
  selectInputPaddingY,
  selectInputPlaceholderColor,
  selectMenuBg,
  selectMenuBorderColor,
  selectMenuBorderRadius,
  selectMenuBorderWidth,
  selectMenuBoxShadow,
  selectMenuItemActiveBg,
  selectMenuItemActiveColor,
  selectMenuItemBg,
  selectMenuItemBorderRadius,
  selectMenuItemColor,
  selectMenuItemFontSize,
  selectMenuItemHoverBg,
  selectMenuItemHoverColor,
  selectMenuItemMarginX,
  selectMenuItemPaddingX,
  selectMenuItemPaddingY,
  selectMenuPaddingY,
  selectMultiBg,
  selectMultiBorderRadius,
  selectMultiColor,
  selectMultiPaddingX,
  selectMultiValueCloseColor,
  selectMultiValueCloseHoverBg,
  selectMultiValueCloseHoverBgInvalid,
  selectMultiValueCloseIcon,
  selectMultiValueCloseIconInvalid,
  selectMultiValueCloseIconSize,
  selectMultiValueCloseSize,
  selectMultiValueFontSize,
  selectMultiValueLineHeight,
  selectMultiValueMarginX,
  sidenavBg,
  sidenavBorderRadius,
  sidenavWidth,
  spacers,
  spinnerSize,
  statusColors,
  statusIconSize,
  statusSize,
  switchBackgroundImage,
  switchDisabledOpacity,
  switchHeight,
  switchWidth,
  tableBgColor,
  tableBorderColor,
  tableBorderWidth,
  tableCellHeight,
  tableCollapseOffset,
  tableFontSize,
  tableHeaderBgColor,
  tableHeaderHeight,
  tableHoverBgColor,
  tableIconFontSize,
  tableResponsiveMinWidth,
  tableScrollStartedBorderColor,
  tableScrollStartedBorderWidth,
  tableScrollStartedGradientFrom,
  tableScrollStartedGradientSize,
  tableScrollStartedGradientTo,
  togglerColor,
  togglerHeight,
  togglerWidth,
  tooltipArrowRadius,
  tooltipArrowSize,
  tooltipBg,
  tooltipBorderRadius,
  tooltipBoxShadow,
  tooltipColor,
  tooltipFontSize,
  tooltipMaxWidth,
  tooltipPaddingX,
  tooltipPaddingY,
  topnavBg,
  topnavBorderColor,
  topnavBorderWidth,
  topnavLinkActiveBg,
  topnavLinkActiveColor,
  topnavLinkColor,
  topnavLinkFontSize,
  topnavLinkHoverBg,
  topnavLinkHoverColor,
  topnavLinkSpacer,
  topnavPaddingX,
  topnavSize,
  transition,
  vrBorderColor,
  vrBorderWidth,
  vrHeight,
  vrSpacingX,
  zindex,
};

export default theme;
