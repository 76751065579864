import {
  ActivityDocumentRequestFragment,
  DealTaskFragmentFragment,
  DocFolderFragment,
  DocRequestFoldersFragment,
  DocRequestFragment,
  DocumentRequestStatus,
} from '~/generated/graphql';

export const FOLDER_LOCALSTORAGE_KEY = (dealId: string) => `${dealId}-expanded-folders`;

export type NormalizedDocRequest = (DocRequestFragment | ActivityDocumentRequestFragment) & { task?: DealTaskFragmentFragment | null };

export type NormalizedFolderTreeItem = {
  id: string;
  title: string;
  children?: string[];
  hasChildren?: boolean;
  hasApprovedChildren?: boolean;
  isExpanded?: boolean;
  isChildrenLoading?: boolean;
  isDroppable?: boolean;
  data?: DocFolderFragment | NormalizedDocRequest;
  isFolder?: boolean;
  isRequest?: boolean;
  depth?: number;
  isDisabled?: boolean;
  adminsOnlyAccess?: boolean | null;
};

export type NormalizedFolderTree = {
  items: Record<string, NormalizedFolderTreeItem>;
  rootId: string;
};

function getExpandedItems(dealId: string): Record<string, boolean> {
  const expandedFolders = typeof window !== 'undefined' ? window.localStorage.getItem(FOLDER_LOCALSTORAGE_KEY(dealId)) : undefined;
  return expandedFolders ? JSON.parse(expandedFolders) : {};
}

const getNormalizedFolderTree = ({
  folders,
  requests,
  _id: dealId,
  expandedFolders,
  isSearchEnabled = false,
}: DocRequestFoldersFragment & { expandedFolders?: Record<string, boolean>; isSearchEnabled?: boolean }): NormalizedFolderTree => {
  const expandedItems = expandedFolders ? expandedFolders : getExpandedItems(dealId);

  const savedFoldersExpandState = Object.keys(expandedItems);

  const allItems = [...folders, ...requests];

  const rootFolders = folders
    .filter((folder) => !folder.parentFolderId)
    .sort((a, b) => a.order - b.order)
    .map(({ _id }) => _id);

  const items: Record<string, NormalizedFolderTreeItem> = {
    root: {
      id: 'root',
      children: rootFolders,
      hasChildren: rootFolders.length !== 0,
      isExpanded: true,
      isChildrenLoading: false,
      title: 'root',
      isDroppable: true,
    },
  };

  folders.forEach((folder) => {
    const children = allItems
      .filter((item) => item.parentFolderId === folder._id)
      .sort((a, b) => a.order - b.order)
      .map((item) => item._id);

    let isFolderExpanded = !folder.parentFolderId;
    if (savedFoldersExpandState.includes(folder._id)) {
      isFolderExpanded = expandedItems[folder._id];
    }
    if (isSearchEnabled) {
      isFolderExpanded = true;
    }

    items[folder._id] = {
      id: folder._id,
      title: folder.name,
      children: children,
      hasChildren: children.length !== 0,
      isChildrenLoading: false,
      // By default open only the first lvl folders
      isExpanded: isFolderExpanded,
      isDroppable: true,
      isFolder: true,
      data: folder,
    };
  });
  folders.forEach((folder) => {
    let adminsOnlyAccess = false;
    let folderId: string | null | undefined = folder._id;
    while (!adminsOnlyAccess && folderId) {
      adminsOnlyAccess = Boolean((items[folderId].data as DocFolderFragment)?.adminsOnlyAccess);
      folderId = items[folderId].data?.parentFolderId;
    }
    items[folder._id].adminsOnlyAccess = adminsOnlyAccess;
  });

  Object.values(requests).forEach((request) => {
    items[request._id] = {
      id: request._id,
      title: request.name,
      isDroppable: false,
      isRequest: true,
      data: request,
    };
    if (request.status === DocumentRequestStatus.Approved) {
      let parentFolderId: string | null | undefined = request.parentFolderId;
      while (parentFolderId) {
        items[parentFolderId].hasApprovedChildren = true;
        parentFolderId = items[parentFolderId].data?.parentFolderId;
      }
    }
  });

  const folderTree = {
    rootId: 'root',
    items,
  };
  return folderTree;
};

export default getNormalizedFolderTree;
