import React, { createContext, useState, FC, ReactNode, Dispatch, SetStateAction, useMemo } from 'react';

type ScrollspyContextType = {
  activeElementId: string;
  setActiveElementId: Dispatch<SetStateAction<string>>;
};

export const ScrollspyContext = createContext<ScrollspyContextType>({} as unknown as ScrollspyContextType);

type ScrollspyContextProviderProps = {
  children: ReactNode;
};

const ScrollspyContextProvider: FC<ScrollspyContextProviderProps> = ({ children }) => {
  const [activeElementId, setActiveElementId] = useState<string>('');
  const context = useMemo(() => ({ activeElementId, setActiveElementId }), [activeElementId, setActiveElementId]);

  return <ScrollspyContext.Provider value={context}>{children}</ScrollspyContext.Provider>;
};

export default ScrollspyContextProvider;
