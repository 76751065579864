import React from 'react';

export const useStateWithLocalStorage = (localStorageKey: string): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [value, setValue] = React.useState((process.browser && localStorage?.getItem(localStorageKey)) || '');

  React.useEffect(() => {
    localStorage?.setItem(localStorageKey, value);
  }, [value, localStorageKey]);

  return [value, setValue];
};
