/**
 * Appends -s for plural words. You can pass a third argument
 * if -s grammar doesn't work for you
 *
 * @param  count     How many items do you have?
 * @param  singular  Singular form. Ex. 'Task'
 * @param  [plural]    Plural form. If not provided will be 'Tasks'
 */
export function pluralize(count: number, singular: string, plural?: string) {
  if (count === 1 || count === -1) return singular;
  if (plural) return plural;
  return singular + 's';
}
