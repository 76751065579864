import { pluralize } from './pluralize';

const formatDate = (date?: Date | string | null, longFormat = false) => {
  if (!date) {
    return 'N/A';
  }

  date = new Date(date);

  const diffMs = new Date().getTime() - date.getTime();
  const diffSec = Math.round(diffMs / 1000);
  const diffMin = Math.round(diffSec / 60);
  const diffHour = Math.round(diffMin / 60);
  const diffDays = Math.round(diffHour / 24);
  const diffMonths = Math.round(diffDays / 30);
  const diffYears = Math.round(diffMonths / 12);

  if (longFormat) {
    if (diffSec < 1) {
      return 'Just now';
    }
    if (diffMin < 1) {
      return `${diffSec} ${pluralize(diffSec, 'second')} ago`;
    }
    if (diffHour < 1) {
      return `${diffMin} ${pluralize(diffMin, 'min')} ago`;
    }
    if (diffHour < 24) {
      return `${diffHour} ${pluralize(diffHour, 'hour')} ago`;
    }
    if (diffDays < 30) {
      return `${diffDays} ${pluralize(diffDays, 'day')} ago`;
    }
    if (diffMonths < 12) {
      return `${diffMonths} ${pluralize(diffMonths, 'month')} ago`;
    }
    return `${diffYears} ${pluralize(diffYears, 'year')} ago`;
  }

  if (diffSec < 1) {
    return 'Just now';
  }
  if (diffMin < 1) {
    return `${diffSec}s ago`;
  }
  if (diffHour < 1) {
    return `${diffMin}m ago`;
  }
  if (diffHour < 24) {
    return `${diffHour}h ago`;
  }
  if (diffDays < 30) {
    return `${diffDays}d ago`;
  }
  if (diffMonths < 12) {
    return `${diffMonths}mo ago`;
  }
  return `${diffYears}y ago`;
};

export default formatDate;
