import { ApolloLink, ApolloClient, InMemoryCache, NormalizedCacheObject, split, Operation, FetchResult, Observable, makeVar } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { print } from 'graphql';
import { createClient, ClientOptions, Client } from 'graphql-ws';
import apolloLogger from 'apollo-link-logger';
import { createUploadLink } from 'apollo-upload-client';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { customFetch } from '../containers/TopProgressBar';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import possibleTypes, {
  BorrowerFragment,
  BorrowerStatus,
  BorrowersTab,
  CompanyFragment,
  DealFieldsFragment,
  DealStatus,
  LenderDealFragment,
  BorrowersElasticSearchQueryVariables,
  LendersElasticSearchQueryVariables,
  QuoteFragment,
  SearchActiveDealsQueryVariables,
  LightCompanyFragment,
} from '~/generated/graphql';
import { TokenKey } from '~/context/AppContext';
import { typeDefs } from '../graphql/schema';
import { getBaseApiUrl, getBaseWebsiteUrl } from '../helpers';
import { v4 as uuid } from 'uuid';
import OpenReplay from '@openreplay/tracker/cjs';
import trackerGraphQL from '@openreplay/tracker-graphql';
import cookies from 'next-cookies';
import { UpdatingStatusLender } from '~/hooks/useUpdateDealLenderStatus';
import { DealLenderPassingContactsOptionValue } from '~/data/dealLenderStatusOptions';

export const tracker = new OpenReplay({
  projectKey: process.env.NEXT_PUBLIC_OPENREPLAY_PROJECT_KEY ?? 'bywKEJkUoFoonkH1DWwu',
  ingestPoint: 'https://openreplay.welcomelend.com/ingest',
});

// @ts-ignore
export const recordGraphQL = tracker.use(trackerGraphQL());

if (process.browser) {
  tracker.start();
  // tracker.setMetadata('baseUrl', getBaseWebsiteUrl());
}

export type CloseDealEventModalVarArg = string | boolean | { _id: string; isClosing: boolean; lenderId?: string };

export const richEditorUploadInProgress = makeVar(false);
export const searchLendersVars = makeVar<LendersElasticSearchQueryVariables>({ limit: 100 });
export const searchBorrowersVars = makeVar<BorrowersElasticSearchQueryVariables>({ tab: BorrowersTab.Active, limit: 100 });
export const borrowerStatusReasonModalVar = makeVar<{ borrowerId?: string; status?: BorrowerStatus }>({});
export const fundSearchString = makeVar<string>('');
export const prevPage = makeVar<string | null>(null);
export const searchActiveDealsVars = makeVar<SearchActiveDealsQueryVariables | null>(null);
export const searchInactiveDealsVars = makeVar<SearchActiveDealsQueryVariables | null>(null);
export const closeDealEventModalVar = makeVar<{
  isOpen: CloseDealEventModalVarArg;
  setIsOpen: (value: CloseDealEventModalVarArg) => void;
}>({
  isOpen: false,
  setIsOpen: () => {},
});
export const dealStatusReasonModalVar = makeVar<{
  status?: DealStatus.Paused | DealStatus.Dead;
  onSubmit?: (reason: string) => void;
}>({});
export const dealStatusLenderModalVar = makeVar<{
  dealId?: string;
  onSubmit?: (lenderId: string, quoteId?: string | null, contactsAction?: DealLenderPassingContactsOptionValue, otherContactsAction?: boolean) => void;
}>({});
export const quoteViewModalVar = makeVar<{
  quotes?: QuoteFragment[];
  quote?: QuoteFragment;
  deal?: DealFieldsFragment | LenderDealFragment;
  lender?: CompanyFragment | LightCompanyFragment;
  showDealInfo?: boolean;
  disableDeleteAction?: boolean;
}>({});
export const quoteDeleteModalVar = makeVar<{
  quote?: QuoteFragment;
  onComplete?: () => void;
}>({});
export const quoteEditModalVar = makeVar<{
  isOpen?: boolean;
  quote?: QuoteFragment;
  lenderId?: string;
  skipLenderStatusUpdate?: boolean;
  onComplete?: (quote: QuoteFragment) => void;
  createQuoteMessage?: string;
}>({});

export const dealBorrowerModalBorrowerVar = makeVar<BorrowerFragment | null>(null);
export const invitationModalVar = makeVar<{
  tab?: 'borrower' | 'lender';
  emails?: string[];
  onSubmit?: () => void;
}>({});

export const dealLenderStatusVar = makeVar<{
  lender?: UpdatingStatusLender | null;
  onSubmit?: (reason: string, lenderPassingContacts?: DealLenderPassingContactsOptionValue) => Promise<void>;
}>({});

export const historyStack = makeVar<{ router: { query: unknown; path: string; pathname: string }; position?: number }[]>([]);
export const historyScrollPosition = makeVar<number | null>(null);
export const historySavingEnabled = makeVar<boolean>(true);

export const pageActiveFinancingOption = makeVar<string | null>(null);
export const escHandlersStack = makeVar<Array<{ handler: (e: KeyboardEvent) => void; id: string | undefined }>>([]);

export const removeLenderFromFindListVar = makeVar<((lenderId: string) => void) | null>(null);

function getParsedCookie() {
  if (process.browser) {
    return document.cookie
      .split(';')
      .map((v) => v.split('='))
      .reduce(
        (acc, v) => {
          acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(v[1]?.trim());
          return acc;
        },
        {} as Record<string, string>,
      );
  }
  return {};
}

class WebSocketLink extends BatchHttpLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (err instanceof Error) {
              sink.error(err);
            } else if (err instanceof CloseEvent) {
              sink.error(
                new Error(
                  `Socket closed with event ${err.code}` + err.reason
                    ? `: ${err.reason}` // reason will be available on clean closes
                    : '',
                ),
              );
            } else {
              console.error(err);
              sink.error(err);
            }
          },
        },
      );
    });
  }
}

const authLink = (sessionId: string, cookieToken?: string) =>
  setContext(({ variables }, { headers }) => {
    const authToken = process.browser ? getParsedCookie()?.[TokenKey.AuthToken] : cookieToken;
    const dealId = (process.browser ? window.location.pathname.match(/\/deal\/(\w+)\//)?.[1] : undefined) ?? variables?.dealId;
    return {
      headers: {
        ...headers,
        'x-wl-session-id': sessionId,
        'x-wl-request-id': uuid(),
        'x-wl-web-version': process.env.RENDER_GIT_COMMIT,
        ...(dealId ? { 'x-wl-deal-id': dealId } : {}),
        authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

const trackerApolloLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const operationDefinition = operation.query.definitions[0];
    return recordGraphQL?.(
      operationDefinition.kind === 'OperationDefinition' ? operationDefinition.operation : 'unknown?',
      operation.operationName,
      operation.variables,
      result,
    );
  });
});

/**
 * Creates and configures the ApolloClient
 */
export default function createApolloClient(
  initialState: NormalizedCacheObject = {},
  ctx?: NextPageContext | GetServerSidePropsContext,
): ApolloClient<NormalizedCacheObject> {
  const reqCookies = ctx && cookies(ctx);
  const sessionId = uuid();
  const splitLink = process.browser
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        new WebSocketLink({
          url: `${getBaseApiUrl().replace(/(http)(s)?:\/\//, 'ws$2://')}/graphql`,
          connectionParams: () => {
            return {
              Authorization: getParsedCookie()?.[TokenKey.AuthToken],
            };
          },
          keepAlive: 30000,
          shouldRetry: () => true,
          retryAttempts: 10000,
        }),
        authLink(sessionId, reqCookies?.[TokenKey.AuthToken]),
      )
    : authLink(sessionId, reqCookies?.[TokenKey.AuthToken]);

  const graphqlUri =
    process.env.IS_PULL_REQUEST !== 'true' &&
    process.env.NEXT_PUBLIC_IS_PULL_REQUEST !== 'true' &&
    typeof window === 'undefined' &&
    process.env.NEXT_PUBLIC_REACT_APP_LOCAL_API_BASE_URL
      ? process.env.NEXT_PUBLIC_REACT_APP_LOCAL_API_BASE_URL
      : `${getBaseWebsiteUrl()}/graphql`;

  const graphqlUploadUri =
    process.env.IS_PULL_REQUEST !== 'true' &&
    process.env.NEXT_PUBLIC_IS_PULL_REQUEST !== 'true' &&
    typeof window === 'undefined' &&
    process.env.NEXT_PUBLIC_REACT_APP_LOCAL_API_BASE_URL
      ? process.env.NEXT_PUBLIC_REACT_APP_LOCAL_API_BASE_URL
      : `${getBaseApiUrl()}/graphql`;

  const apolloLink = [
    // createPersistedQueryLink({ sha256 }),
    new ApolloLink((operation, forward) => {
      if (process.browser) {
        // @ts-ignore
        window.apolloCache = operation.getContext().cache;
      }
      return forward!(operation);
    }),
    onError(() => {
      // if (process.env.REACT_APP_ENABLE_DEBUG_LOG !== 'true') {
      //   return;
      // }
      // if (graphQLErrors) {
      //   graphQLErrors.forEach((error) => {
      //     //   const { message, locations, path } = error;
      //     // Sentry.captureException(error);
      //   });
      // }
    }),
    trackerApolloLink,
    splitLink,
    createUploadLink({
      uri: ({ operationName }) => (['uploadDocument', 'createDocumentRequest', 'uploadLenderFile'].includes(operationName) ? graphqlUploadUri : graphqlUri),
      fetch: customFetch,
      headers: { 'Apollo-Require-Preflight': 'true' },
    }),
  ];

  if (process.browser && (process.env.NODE_ENV === 'development' || process.env.REACT_APP_GRAPHQL_LOGGER === 'true')) {
    apolloLink.unshift(apolloLogger);
  }

  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    ssrForceFetchDelay: 100,
    link: ApolloLink.from(apolloLink),
    cache: new InMemoryCache({
      possibleTypes: possibleTypes.possibleTypes,
      typePolicies: {
        Query: {
          fields: {
            getUserInvitations: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
            searchDocuments: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
            getUsersAndInvitations: {
              merge: (_, incoming = []) => {
                return incoming;
              },
              keyArgs: (ctx) => {
                return ctx?.dealId;
              },
            },
            lendersElasticSearch: {
              keyArgs: ['filter', 'search', 'sort'],
            },
            quotes: {
              keyArgs: ['filter', 'search', 'sort'],
            },
            quotesElasticSearch: {
              keyArgs: ['filter', 'search', 'sort'],
            },
            dealsElasticSearch: {
              keyArgs: ['filter', 'search', 'sort'],
            },
            getLenderLoans: {
              keyArgs: ['lenderId', 'dealId', 'search', 'sort', 'similarOnly'],
            },
            getLenderLoanTransactions: {
              keyArgs: ['loanId', 'dealId', 'search', 'sort'],
            },
            lenderDeals: {
              keyArgs: ['lenderId', 'dealId', 'similarOnly', 'search', 'sort'],
            },
            borrowerDeals: {
              keyArgs: ['borrowerId', 'dealId', 'search', 'sort'],
            },
            getSingleDeal: {
              keyArgs: ['id'],
            },
          },
        },
        Subscription: {
          fields: {
            requestDocumentsUpdated: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
          },
        },
        LendersSearch: {
          fields: {
            lenders: {
              merge: (existing, incoming, { variables }) => {
                if (!variables?.skip) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        DealsSearch: {
          fields: {
            deals: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        Quotes: {
          fields: {
            quotes: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        QuotesSearch: {
          fields: {
            quotes: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        ProgramsSearch: {
          fields: {
            programs: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        BorrowersSearch: {
          fields: {
            borrowers: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        DealActivities: {
          fields: {
            groupedActivities: {
              merge: (existing = [], incoming = [], args) => {
                // We need to replace the cache value when skip = 0(fresh query load)
                if (args.variables?.skip === 0) {
                  return incoming;
                }
                // Just get uniq items. incoming = [{__ref: ID}, ...]
                return [...new Set([...existing, ...incoming].map((item) => item.__ref))].map((item) => ({ __ref: item }));
              },
            },
          },
        },
        GroupedActivity: {
          fields: {
            activities: {
              merge: (existing = [], incoming = [], args) => {
                // We need to replace the cache value when skip = 0(fresh query load)
                if (args.variables?.skip === 0) {
                  return incoming;
                }
                // Just get uniq items. incoming = [{__ref: ID}, ...]
                return [...new Set([...existing, ...incoming].map((item) => item.__ref))].map((item) => ({ __ref: item }));
              },
            },
          },
        },
        LenderActivities: {
          fields: {
            groupedActivities: {
              merge: (existing = [], incoming = [], args) => {
                // We need to replace the cache value when skip = 0(fresh query load)
                if (args.variables?.skip === 0) {
                  return incoming;
                }
                // Just get uniq items. incoming = [{__ref: ID}, ...]
                return [...new Set([...existing, ...incoming].map((item) => item.__ref))].map((item) => ({ __ref: item }));
              },
            },
          },
        },
        LenderLoans: {
          fields: {
            lenderLoans: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        LenderLoanTransactions: {
          fields: {
            lenderLoanTransactions: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        LenderDeals: {
          fields: {
            lenderDeals: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        BorrowerDeals: {
          fields: {
            deals: {
              merge: (existing, incoming, { variables }) => {
                if (variables?.skip === 0) {
                  return incoming;
                }
                return [...(existing ?? []), ...(incoming ?? [])];
              },
            },
          },
        },
        DealSearchResult: {
          fields: {
            project: {
              merge: (_, incoming = {}) => {
                return incoming;
              },
            },
          },
        },
        Deal: {
          fields: {
            project: {
              merge: (_, incoming = {}) => {
                return incoming;
              },
            },
            market: {
              merge: (_, incoming = {}) => {
                return incoming;
              },
            },
            financials: {
              merge: (_, incoming = {}) => {
                return incoming;
              },
            },
            units: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((unit, inx) => ({
                    ...unit,
                    _id: __IS_SERVER__ ? `unit-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            settings: {
              merge: (curr, incoming) => {
                if (curr || incoming) {
                  return { ...(curr ?? {}), ...(incoming ?? {}) };
                }
                return null;
              },
            },
          },
        },
        DealProject: {
          fields: {
            nearbyPlaces: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((nearbyPlace, inx) => ({
                    ...nearbyPlace,
                    _id: __IS_SERVER__ ? `nearbyPlace-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
          },
        },
        DealMarketSaleComp: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealMarketLeaseComp: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealMarket: {
          fields: {
            newSaleComps: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((saleComp, inx) => ({
                    ...saleComp,
                    _id: __IS_SERVER__ ? `saleComp-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            newLeaseComps: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((leaseComp, inx) => ({
                    ...leaseComp,
                    _id: __IS_SERVER__ ? `leaseComp-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            majorEmployers: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((majorEmployer, inx) => ({
                    ...majorEmployer,
                    _id: __IS_SERVER__ ? `majorEmployer-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            recentAccolades: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((recentAccolade, inx) => ({
                    ...recentAccolade,
                    _id: __IS_SERVER__ ? `recentAccolade-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            economicDrivers: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((economicDriver, inx) => ({
                    ...economicDriver,
                    _id: __IS_SERVER__ ? `economicDriver-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            stats: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((stat, inx) => ({
                    ...stat,
                    _id: __IS_SERVER__ ? `stat-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            localEconomyStats: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((localEconomyStat, inx) => ({
                    ...localEconomyStat,
                    _id: __IS_SERVER__ ? `localEconomyStat-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            housingStats: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((housingStat, inx) => ({
                    ...housingStat,
                    _id: __IS_SERVER__ ? `housingStat-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            jobMarketStats: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((jobMarketStat, inx) => ({
                    ...jobMarketStat,
                    _id: __IS_SERVER__ ? `jobMarketStat-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
          },
        },
        DealMarketMajorEmployers: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealMarketRecentAccolades: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealMarketEconomicDrivers: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealMarketStats: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealTeam: {
          fields: {
            sponsors: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((sponsor, inx) => ({
                    ...sponsor,
                    _id: __IS_SERVER__ ? `sponsor-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            nonSponsorMembers: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((nonSponsorMember, inx) => ({
                    ...nonSponsorMember,
                    _id: __IS_SERVER__ ? `nonSponsorMember-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            pastProjects: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((pastProject, inx) => ({
                    ...pastProject,
                    _id: __IS_SERVER__ ? `pastProject-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
          },
        },
        DealTeamSponsor: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealTeamNonSponsorMember: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealTeamPastProject: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealWelcomelendTeam: {
          fields: {
            advisors: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((advisor, inx) => ({
                    ...advisor,
                    _id: __IS_SERVER__ ? `advisor-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
          },
        },
        DealWelcomelendTeamAdvisor: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DealProjectNearbyPlace: {
          fields: {
            _id(existing) {
              return existing ?? uuid();
            },
          },
        },
        DocumentRequestFolders: {
          fields: {
            folders: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
            requests: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
          },
        },
        DealNewFinancing: {
          fields: {
            units: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((unit, inx) => ({
                    ...unit,
                    _id: __IS_SERVER__ ? `unit-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            otherIncome: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
            operatingExpenses: {
              merge: (_, incoming = []) => {
                return incoming;
              },
            },
            saleExpenses: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((saleExpense, inx) => ({
                    ...saleExpense,
                    _id: __IS_SERVER__ ? `saleExpense-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            refinanceFees: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((fee, inx) => ({
                    ...fee,
                    _id: __IS_SERVER__ ? `fee-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            costsSpentToDate: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((costSpentToDate, inx) => ({
                    ...costSpentToDate,
                    _id: __IS_SERVER__ ? `costSpentToDate-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            remainingCosts: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((remainingCost, inx) => ({
                    ...remainingCost,
                    _id: __IS_SERVER__ ? `remainingCost-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
            seniorDebtFees: {
              merge: (_, incoming = []) => {
                return (
                  (incoming as any[])?.map((fee, inx) => ({
                    ...fee,
                    _id: __IS_SERVER__ ? `fee-${inx}` : uuid(),
                  })) ?? null
                );
              },
            },
          },
        },
      },
    }).restore(initialState),
    typeDefs,
  });
}
