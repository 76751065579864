import Router from 'next/router';

import { gql } from '@apollo/client';

import NProgress from './nprogress';

NProgress.configure({
  speed: 300,
});

let timer: NodeJS.Timeout;
let activeRequests = 0;
const delay = 0;

function load() {
  timer = setTimeout(function () {
    process.browser && NProgress?.start();
  }, delay); // only show progress bar if it takes longer than the delay
}

function stop() {
  if (activeRequests > 0) {
    return;
  }

  clearTimeout(timer);
  process.browser && NProgress?.done();
}

Router.events.on('routeChangeStart', load);
Router.events.on('routeChangeComplete', stop);
Router.events.on('routeChangeError', stop);

/**
 * Here we hold all operations where we don't want to show the loader.
 * It should be query/mutation name.
 * 1. getUsers -> borrowers/lenders users autocomplete
 */
const OPS_TO_SKIP = [
  'getUsers',
  'updateDealStatus',
  'lendersElasticSearch',
  'getDomainData',
  'getUserInvitationsCount',
  'getLatestUpdatedDocNames',
  'getReportDropoffFunnel',
  'assignDocRequests',
  'createNote',
  'updateNote',
  'createOpenAICompletion',
  'globalElasticSearch',
  'lockDealNewFinancing',
  'unlockDealNewFinancing',
  'lockDeal',
  'unlockDeal',
  'ackDealUnlocked',
  'getDealMarketDescription',
  'getDealMarketJobMarketDescription',
  'getDealMarketLocalEconomyDescription',
  'getDealMarketHousingDescription',
  'getDealProjectLocationDescription',
  'unlockQuoteMatrix',
  'lockQuoteMatrix',
];

export const customFetch: typeof fetch = async (...args) => {
  if (!process.browser) {
    try {
      // @ts-ignore
      const response = await fetch(...args);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const [, variables] = args;

  // Prevent parsing FormData + infinity render react issue
  const parsedBody = typeof variables?.body === 'string' ? JSON.parse(variables?.body) : {};

  /**
   * In the queries we are using "cache-and-network" which means that we will display cache version instantly
   * and in the background we will try to refetch the data - in this case we should skip displaying nprogres.
   * So we displaying nprogres only on "new" queries
   */
  let cacheVersion;
  try {
    // @ts-ignore
    cacheVersion = window?.apolloCache?.readQuery({ variables: parsedBody.variables, query: gql(parsedBody.query) });
  } catch {
    cacheVersion = null;
  }

  /**
   * In case if it's a deals page we are doing prefetching on hover
   * So we should hide nprogress on such scenario
   */
  const isDealsPage = window?.location?.pathname.includes('/deals');
  const PREFETCHING_QUERIES = ['getDealsForReports', 'getSingleDeal'];
  const isPrefetchingOnProgress = isDealsPage && PREFETCHING_QUERIES.includes(parsedBody?.operationName);

  const dealFinancingAutosave = ['updateDealNewFinancing'].includes(parsedBody?.operationName) && !parsedBody.variables?.financing?.isPublished;
  const dealSummaryAutosave = parsedBody?.operationName === 'updateDealSummary' && !parsedBody.variables?.publish;
  const quoteMatrixAutosave = parsedBody?.operationName === 'updateQuoteMatrix' && !parsedBody.variables?.publish;

  if (
    activeRequests === 0 &&
    !OPS_TO_SKIP.includes(parsedBody?.operationName) &&
    !dealFinancingAutosave &&
    !dealSummaryAutosave &&
    !quoteMatrixAutosave &&
    !cacheVersion &&
    !isPrefetchingOnProgress
  ) {
    load();
  }

  if (!cacheVersion) {
    activeRequests++;
  }

  try {
    const response = await fetch(...args);
    return response;
  } catch (error) {
    return Promise.reject(error);
  } finally {
    if (activeRequests > 0) {
      activeRequests -= 1;
    }

    stop();
  }
};

export default function TopProgressBar() {
  return null;
}
