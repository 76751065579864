import React, { FC, Fragment, useEffect, useRef } from 'react';
import '@szhsin/react-menu/dist/core.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'slick-carousel/slick/slick.css';
import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import { Global, Theme } from '~/theme';
import { prevPage, historyStack, historyScrollPosition, historySavingEnabled } from '~/lib/apolloClient';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import isPropValid from '@emotion/is-prop-valid';
import ScrollspyContextProvider from '~/context/ScrollspyContext';
// @ts-ignore
import TimeMe from '~/helpers/timeme';
import type { AppProps } from 'next/app';
import type { NextPage } from 'next';
import * as Sentry from '@sentry/nextjs';

if (process.env.NEXT_PUBLIC_SENTRY_DSN && process.env.NEXT_PUBLIC_SENTRY_DSN !== 'undefined') {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    allowUrls: [/welcomelend\.com/, /onrender\.com/],
  });
}

const TopProgressBar = dynamic(
  () => {
    return import('~/containers/TopProgressBar');
  },
  { ssr: false },
);

export type PageProps = Record<string, any>;

export type NextLayout = FC<{
  pageProps: PageProps;
  children: React.ReactNode;
}>;

export type NextPageWithLayout<Props = object> = NextPage<Props> & { Layout?: NextLayout };

const MyApp = ({ Component, pageProps }: AppProps<PageProps> & { Component: NextPageWithLayout }) => {
  const router = useRouter();

  const isOutdatedRef = useRef(false);
  const prevAsPathRef = useRef('');

  // compare current FE version and remote FE version
  useEffect(() => {
    const checkVersion = async () => {
      try {
        const { version } = (await (await fetch('/api/version')).json()) as { version: string };
        if (version !== process.env.RENDER_GIT_COMMIT) {
          isOutdatedRef.current = true;
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (!isOutdatedRef.current) {
          setTimeout(checkVersion, 10 * 1000);
        }
      }
    };
    if (process.env.RENDER_GIT_COMMIT) {
      checkVersion();
    }
  }, []);

  // don't allow tab live more than 12 hours
  useEffect(() => {
    setTimeout(
      () => {
        isOutdatedRef.current = true;
      },
      12 * 60 * 60 * 1000,
    );
  }, []);

  // refresh the page if new version was deployed
  useEffect(() => {
    if (isOutdatedRef.current) {
      window.location.reload();
    }
    prevAsPathRef.current = router.asPath;
  }, [router.asPath]);

  useEffect(() => {
    TimeMe.initialize({
      currentPageName: router.asPath,
      idleTimeoutInSeconds: 10,
      websocketOptions: {
        websocketHost: `${getBaseApiUrl().replace(/(http)(s)?:\/\//, 'ws$2://')}/online`,
      },
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      TimeMe.changePage(url);
      prevPage(router.pathname);
      if (historySavingEnabled()) {
        historyStack().push({
          router: {
            query: router.query,
            path: router.asPath,
            pathname: router.pathname,
          },
          position: window?.pageYOffset,
        });
      } else {
        historySavingEnabled(true);
      }
    };

    const handleRouteChangeCompleted = (url: string) => {
      if (url !== '/lenders') {
        const scrollPosition = historyScrollPosition();
        if (scrollPosition) {
          window.scrollTo({ top: scrollPosition });
          historyScrollPosition(null);
        }
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeCompleted);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeCompleted);
    };
  }, [router.asPath]);

  useEffect(() => {
    const handleRouteChange = () => {
      document.body.setAttribute('style', '');
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Head>
      <TopProgressBar />
      <ThemeProvider theme={Theme}>
        <ScrollspyContextProvider>
          <StyleSheetManager shouldForwardProp={(prop, elementToBeCreated) => typeof elementToBeCreated !== 'string' || isPropValid(prop)}>
            <Global />
            {Component.Layout ? (
              <Component.Layout pageProps={pageProps}>
                <Component {...pageProps} />
              </Component.Layout>
            ) : (
              <Fragment>
                <Component {...pageProps} />
              </Fragment>
            )}
          </StyleSheetManager>
        </ScrollspyContextProvider>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
