import React, { useContext, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useStateWithLocalStorage } from '~/hooks/useStateWithLocalStorage';

const noop = () => { };

export enum TokenKey {
  AuthToken = 'sessionToken',
}

export const AppContext = React.createContext<{
  signIn: (token: string) => void;
  signOut: () => void;
}>({
  signIn: noop,
  signOut: noop,
});

const AppContextProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const apolloClient = useApolloClient();
  const [appState, setAppState] = useState<{ token: string | null }>({ token: null });
  const [, setToken] = useStateWithLocalStorage('sessionToken');

  function saveToken(token: string, { remove = false }: { remove?: boolean } = {}) {
    // const exp = decodeJwt(token).exp;
    // Temporary disable it
    // if (typeof exp !== 'number') {
    //   throw new Error('JWT does not have exp field. Cannot calculate cookie maxAge');
    // }
    // let maxAge = `Max-Age=${exp - Math.floor(Date.now() / 1000)}`;
    let maxAge = `Max-Age=${90 * 24 * 60 * 60}`;

    if (remove) {
      maxAge = `Expires=${new Date(0).toUTCString()}`;
    }

    let cookieSecurity = 'Secure';
    if (location.hostname === 'localhost') {
      cookieSecurity = 'Domain=localhost; SameSite=Lax';
    }

    document.cookie = `${TokenKey.AuthToken}=${token}; Path=/; ${maxAge}; ${cookieSecurity}`;

    setToken(remove ? '' : token);

    setAppState((appState) => ({
      ...appState,
      token: remove ? null : token,
    }));
  }

  async function signOut() {
    saveToken(' ', { remove: true });
    await apolloClient.clearStore();
  }

  function signIn(token: string) {
    saveToken(token);
  }

  const app = useMemo(
    () => ({
      signOut,
      signIn,
      appState,
    }),
    [appState],
  );

  return <AppContext.Provider value={app} {...props} />;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
