const formatExtension = (name = '', slice = false) => {
  let extension = name
    .toLowerCase()
    .split('.')
    .pop()!;

  if (slice) {
    extension = extension.slice(0, 3);
  }

  return extension;
};

export default formatExtension;
