function intlFormat(num: number, maximumFractionDigits = 1) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits }).format(num);
}

function abbreviateMoney(value: number): string {
  const sign = value < 0 ? '-$' : '$';
  value = Math.abs(value);

  if (value >= 1000000000) {
    return sign + intlFormat(value / 1000000000) + 'B';
  }

  if (value >= 1000000) {
    return sign + intlFormat(value / 1000000) + 'M';
  }

  if (value >= 1000) {
    return sign + intlFormat(value / 1000) + 'K';
  }

  return sign + intlFormat(value, 0);
}

export default abbreviateMoney;
