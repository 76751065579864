const formatMoney = (value?: number | null, opt?: Intl.NumberFormatOptions & { fractionDigits?: number }) => {
  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    ...opt,
    ...(typeof opt?.fractionDigits === 'number'
      ? {
          minimumFractionDigits: opt.fractionDigits,
          maximumFractionDigits: opt.fractionDigits,
        }
      : {}),
  });

  return moneyFormatter.format(value || 0);
};

export default formatMoney;
